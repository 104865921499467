/**
 * @author QsPromise
 * @create 2023-04-04 15:19 PM
 * @license MIT
 * @LastEditor QsPromise
 * @lastEditTime 2023-04-06 14:03 PM
 * @filePath \amyaiclient\src\plugins\vant.ts
 * @desc
 */
import Vue from 'vue';
// 在这里引入你所需的组件
import { Button, Skeleton, Icon, Col, Row, Field,Toast  } from 'vant';

// 按需引入UI组件
Vue.use(Button);
Vue.use(Skeleton);
Vue.use(Icon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Field);
Vue.use(Toast);