/**
 * @author QsPromise
 * @create 2023-04-04 14:30 PM
 * @license MIT
 * @LastEditor QsPromise
 * @lastEditTime 2023-04-04 15:19 PM
 * @filePath \amyaiclient\src\main.ts
 * @desc
 */
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import '@/plugins/vant';
import router from './router';


Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
